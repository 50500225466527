/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

@media (min-width: 650px )and (max-width: 950px) and (max-height:500px) {
  .home-para {
    margin-left: 40%;
    
  }
  .background-image{
    height: 100vh;
  }
  .about{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:50px;
    padding-top: 10%;
  }
  .Office{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:15%;
    padding-top: 10%;
  }
  .contact{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:10%;
    padding-top: 5%;

  }
  .contact p{
    font-size: 14px;
  }
  .contact form input{
    font-size: 14px;
  }

  .work{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px; 
  }
 .workfeture {
  height: 650px;
 }
.aboutus .about{
  padding-top: 5%;
}
.aboutus .about p{
font-size: 10px;
}
.aboutus .contact{
  padding-top: 1%;
}
.aboutus .contact p{
  font-size: 10px;
} 
.aboutus .contact form input{
  font-size: 10px;
}

  
}
@media (width:280px) {
  .home{
    height: 750px;
  }
  .home-img{
    height: 750px;
  }
  .nav-img{
    width: 200px;
  }
}

.accept-button {
  color: white;
  background-color: rgb(51, 51, 211);
  padding: 0 16px;
  height: 33px;
  width: 150px;
margin: 1px 5px;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
}

.cookies-popup {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #0f0f0f;
  color: white;
  padding: 10px;
  text-align: left;
  z-index: 1000;
  transition: transform 0.3s;
  transform: translateY(100%);
}

.cookies-popup.show {
  transform: translateY(0);
}

.cookies-content {
  max-width: 600px;
  margin: 0 auto;
}



.accept-button:hover {
  background-color: darkblue;
}

.play-button {
  /* Adjust the positioning as needed for centering the icon */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 650px ){
.workvideo{
  width: 100%;
}
.play-button2{
  position: absolute;
  top:40%;
}
}
@media (min-width: 300px ){
  .play-button2{
    position: absolute;
    top:40%;
    left:30%;
  }
  /* .wvideo{
    position: absolute;
    top:50%;
    left:50%;
  } */
  }

 
  
  

